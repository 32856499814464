define(['jquery', 'fancyBox'], function($) {
  
  var _sizeGuide = {

    _init: function() {
      var sizeGuides = $('[data-component="sizeGuide"]');
      this._bindEvents(sizeGuides);
    },

    _bindEvents: function(sizeGuides) {
      sizeGuides.on('click', function() {
        //en_GB/GBP/sizeguidejson/11095772.awesome
        var url = window.siteObj.siteNonSecureURL;
        var locale = window.siteObj.customerLocale;
        var currency = window.siteObj.currencyType;
        var productId = window.siteObj.productID;
        var endpoint = url + locale + '/' + currency + '/sizeguidejson/' + productId + '.awesome';
        _sizeGuide._call(endpoint, _sizeGuide._success);
      });
    },

    _call: function(url, callback) {
      $.ajax({
        url: url,
        method: 'GET',
        success: function(data) {
          callback(data);
        }
      });
    },

    _success: function(data) {
      var html = data.sizeGuide;
      if ($().fancybox) {
        $.fancybox(html, {
          'width': 200,
          'height': 'auto',
          'autoDimensions': false
        });
      }
    }
  };

  _sizeGuide._init();

  return _sizeGuide;
});
